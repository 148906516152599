import React from "react"
import { useContext } from "react"
import "./ourBoardMembers.scss"
// import Swiper core and required modules
import { createMarkup, string_translation, chunk_array } from "../../../utils"
import { useStaticQuery, graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

export default function OurBoardMembers(props) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        obm_ar: translateString(language: AR, string: "OUR BOARD MEMBERS")
        obm_en: translateString(language: EN, string: "OUR BOARD MEMBERS")
      }
    }
  `)

  const ourBoardMembers = props.content.ourBoardMembers
  const ourLeader = props.content.ourLeader
  let chunked_array = chunk_array(ourBoardMembers, 3)

  return (
    <>
      <section className="on-board-members">
        <div className="on-board-header desktop-show">
          <div className="container">
            <div className="on-board-header-wrapper ">
              <div className="title h2">
                {string_translation(stringTranslations, "obm", locale)}
              </div>
              <div className="logo">
                <img src="/images/about-us/on-board-header-logo.svg" alt={string_translation(stringTranslations, "obm", locale)} />
              </div>
            </div>
          </div>
        </div>
        <div className="on-board-header mobile-show">
          <div className="container">
            <div className="on-board-header-wrapper ">
              <div className="logo">
                <img
                  src="/images/about-us/on-board-header-logo-mobile.svg"
                  alt={string_translation(stringTranslations, "obm", locale)}
                />
              </div>
              <div className="title h2">
                {string_translation(stringTranslations, "obm", locale)}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="royal-member-div">
            <div className="col-group">
              <div className="col-dt-4 desktop-show">
                <div className="logo-wrapper">
                  <img src="/images/about-us/royal-member-logo.svg" alt="Royal Member" />
                </div>
              </div>
              <div className="col-dt-8">
                <div className="royal-member-wrapper">
                  <div className="col-group">
                    <div className="col-dt-5">
                      <div className="royal-member-img">
                        <img
                          src={
                            props.content.ourLeader[0].featuredImage?.node
                              ?.localFile?.publicURL
                          }
                          alt={props.content.ourLeader[0].title}
                        
                        />
                      </div>
                    </div>
                    <div className="col-dt-7 royal-member-side">
                      <div className="royal-member-info-wrapper">
                        <div
                          className="name h2"
                          dangerouslySetInnerHTML={createMarkup(
                            props.content.ourLeader[0].title
                          )}
                        ></div>
                        <div className="position memberhead">
                        {ourLeader[0].membersTypes.nodes.map((item, index) => ( 
                          <span key={"ol"+index}>{item.name}</span>
                        ))}
                        </div>
                        <div
                          className="desc"
                          dangerouslySetInnerHTML={createMarkup(
                            props.content.ourLeader[0].content
                          )}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="members-div">
            <div id="membersSwiper">
              {chunked_array.map((member_array, index) => (
                <div className="row-wrapper" key={"mem"+index}>
                  {member_array.map((member, index) => (
                    <div className="swiper-slide" key={"memm"+index}>
                      <div className="our-member-wrapper">
                        <div className="member-img">
                          <div className="img-wrapper">
                            <img
                              src={
                                member.featuredImage.node?.localFile?.publicURL
                              }
                              alt={member.title}
                            />
                          </div>
                        </div>
                        <div className="info-wrapper">
                          <div
                            className="name h2"
                            dangerouslySetInnerHTML={createMarkup(member.title)}
                          ></div>
                          <div className="position">
                          {member?.membersTypes?.nodes.map((item, i) => ( 
                            <span key={"ol"+i+index}>{item.name}</span>
                          ))}
                          </div>
                          <div
                            className="desc"
                            dangerouslySetInnerHTML={createMarkup(
                              member.content
                            )}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
