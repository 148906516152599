import React from "react"
import "./leader.scss"

// import Swiper core and required modules
import { createMarkup, string_translation, chunk_array } from "../../../utils"
import { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

export default function OurLeaderShip(props) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        leadership_ar: translateString(language: AR, string: "OUR LEADERSHIP")
        leadership_en: translateString(language: EN, string: "OUR LEADERSHIP")
      }
    }
  `)

  const ourLeadership = props.content.ourLeadership
  let chunked_array = chunk_array(ourLeadership, 3)

  return (
    <>
      <section className="our-leadership">
        <div className="our-leadership-header desktop-show">
          <div className="container">
            <div className="on-board-header-wrapper ">
              <div className="title h2">
                {string_translation(stringTranslations, "leadership", locale)}
              </div>
            </div>
          </div>
        </div>
        <div className="our-leadership-header mobile-show">
          <div className="container">
            <div className="on-board-header-wrapper ">
              <div className="title h2">
                {string_translation(stringTranslations, "leadership", locale)}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="members-div">
            <div id="membersSwiper">
              {chunked_array.map((member_array, index) => (
                <div className="row-wrapper" key={"a"+index}>
                  {member_array.map((member, index) => (
                    <div className="swiper-slide mem-swiper-slide" key={"b"+index}>
                      <div className="member-wrapper">
                        <div className="member-img">
                          <div className="img-wrapper">
                            <img
                              src={
                                member.featuredImage?.node?.localFile?.publicURL
                              }
                              alt={member.title}
                            />
                          </div>
                        </div>
                        <div className="info-wrapper">
                          <div
                            className="name h2"
                            dangerouslySetInnerHTML={createMarkup(member.title)}
                          ></div>
                          <div className="position">
                            <span
                              dangerouslySetInnerHTML={createMarkup(
                                member?.membersTypes?.nodes[0]?.name
                              )}
                            ></span>
                          </div>
                          <div
                            className="desc"
                            dangerouslySetInnerHTML={createMarkup(
                              member.content
                            )}
                          ></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
