import * as React from "react"
import CareerFirstComponent from "../components/CareerFirstComponent"
import WhoWeAre from "../components/WhoWeAre"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useContext } from "react"
import ComboWrapperAboutUs from "../components/ComboWrapperAboutUs"

export default function AboutUs(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
        <WhoWeAre
          title={data.wpPage?.title}
          name={data.wpPage.pageSubtitle?.subtitle}
          description={data.wpPage.pageDescription?.pageDescription}
        />
        <CareerFirstComponent data={data?.wpPage}/>
        
        <ComboWrapperAboutUs
          content={data.wpPage.OurBoardMembersAndLeadershipFields}
          locale={locale}
        />
        
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query pageData($databaseId: Int) {
    wpPage(databaseId: { eq: $databaseId }) {
      id
      title
      pageSubtitle {
        subtitle
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      content
      date
      pageDescription {
        fieldGroupName
        pageDescription
      }
      OurBoardMembersAndLeadershipFields {
        ourBoardMembers {
          ... on WpMemberAndLeader {
            id
            title
            content
            featuredImage {
              node {
                localFile {
                  publicURL
                }
              }
            }
            membersTypes {
              nodes {
                name
              }
            }
          }
        }
        ourLeader {
          ... on WpMemberAndLeader {
            id
            title
            content
            featuredImage {
              node {
                localFile {
                  publicURL
                }
              }
            }
            membersTypes {
              nodes {
                name
              }
            }
          }
        }
        ourLeadership {
          ... on WpMemberAndLeader {
            id
            title
            content
            featuredImage {
              node {
                localFile {
                  publicURL
                }
              }
            }
            membersTypes {
              nodes {
                name
              }
            }
          }
        }
        visionHeading
        visionText
        visionImage{
            localFile {
              publicURL
            }
        }
        missionHeading
        missionText
        missionImage{
            localFile {
              publicURL
            }
        }
      }
    }
  }
`
