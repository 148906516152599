import * as React from "react"
import {useContext} from "react"
import "./vision.scss"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import {string_translation, createMarkup } from '../../../utils'

export default function Vision(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)



  return (
    <>
      <section className="vision">
        <div className="vision-wrapper">
          <div className="container">
              <div className="col-group space">
               
                <div className="col-dt-6 middleTextAbout">
                  <div className="text-wrapper-vision">
                    <div className="big-title h2">
                      {props.content.visionHeading}
                    </div>
                    <div className="desc" dangerouslySetInnerHTML={createMarkup(props.content.visionText)}>
                 </div>
                    
                  </div>
                </div>
                <div className="col-dt-6">
                  <div className="wrapper">
                    <div className="img-wrapper-vision">
                      <img src={props.content.visionImage?.localFile?.publicURL} alt="" />
                    </div>
                  </div>
                </div>
              </div>
             
              <div className="col-group d-none">
                <div className="col-dt-6">
                  <div className="wrapper">
                    <div className="img-wrapper">
                      <img src={props.content.missionImage?.localFile?.publicURL} alt="" />
                    </div>
                  </div>
                </div>
                <div className="col-dt-6 middleTextAbout">
                  <div className="text-wrapper">
                    <div className="big-title h2">
                      {props.content.missionHeading}
                    </div>
                    <div className="desc" dangerouslySetInnerHTML={createMarkup(props.content.missionText)}>
                 </div>
                    
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  )
}
