import React, { useEffect, useContext, useState } from "react"
import "./ComboWrapperAboutUs.scss"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"


import Vision from "../Vision"
import OurBoardMembers from "../OurBoardMembers"
import OurLeaderShip from "../OurLeaderShip"

export default function ComboWrapperAboutUs(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  let hash="#bod";
  if (typeof window !== 'undefined') {
    try{
  if(sessionStorage.getItem('hashabout')){
    hash=sessionStorage.getItem('hashabout');
  }
}catch(ex){
        
}
  if (window.location.hash) {
    hash=window.location.hash;
  }
}

useEffect(
  () => {
    if (hash) {
       setActiveTab(hash)
    }
  }
)
function setActiveTab(hashurl){
  var elements = document.getElementsByClassName("aboutuslistheader")[0].getElementsByTagName("li");
    for (var i = 0; i < elements.length; i++) {
    if(elements[i].dataset.tab==hashurl){
      elements[i].classList.add("active");
    }else{
      elements[i].classList.remove("active");
    }
  }


  var elementsdown = document.getElementsByClassName("listbottomabout")[0].getElementsByTagName("div");

    for (var i = 0; i < elementsdown.length; i++) {
      if(elementsdown[i].id!=""){
    if('#'+elementsdown[i].id==hashurl){
      elementsdown[i].classList.add("active");
    }else{
      elementsdown[i].classList.remove("active");
    }
  }
  }
}
function updatehash(hash){
  if (typeof window !== 'undefined') {
    try{
    sessionStorage.setItem('hashabout', hash);
  }catch(ex){
        
  }
  }
  
var arabic = document.querySelectorAll(".smail-menu-item,.arabic");
  try{
   for ( let i=0 ; i < arabic.length ; i++) {
    var elem = arabic[i];
     if(elem.childNodes){
     const url = elem.childNodes[0].href;
     const urlObj = new URL(url);
      urlObj.hash = hash;
      const result = urlObj.toString();
     elem.childNodes[0].href=result;
     }

    }
  }catch(aaa){

  }
}
 
 
  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      who_we_are_ar:translateString(language: AR, string: "حول المشروع")
      who_we_are_en:translateString(language: EN, string: "WHO WE ARE")
      board_of_directors_ar:translateString(language: AR, string: "مجلس الإدارة")
      board_of_directors_en:translateString(language: EN, string: "BOARD OF DIRECTORS")
      }
  }`)

 

 

  return (
    <>
      <section className="combo-wrapper-about-us">
        <div className="container">
     
          <div className="combo-box-tab">
            <ul className="tabs tabs-list h2 aboutuslistheader" data-controls="#drf">

              <li data-tab="#whoWeAre" onClick={(e) => updatehash('#whoWeAre')}>
                {string_translation(stringTranslations, "who_we_are", locale)}
              </li>
        
         
               <li data-tab="#bod" onClick={(e) => updatehash('#bod')}>
               {string_translation(stringTranslations, "board_of_directors", locale)}
             </li>
       
            </ul>
          </div>
          <div className="tab-container listbottomabout" id="drf">
            <div className="resizing" id="whoWeAre">
             
            <Vision content={props.content} />
            </div>
           
            <div className="active resizing" id="bod">
            <OurBoardMembers
             content={props.content}
              locale={locale}
            />
           <OurLeaderShip
              content={props.content}
              locale={locale}
           />
            </div>
         
          </div>
        
        </div>
      </section>
    </>
  )
}
