import React from "react"
import "./careerfirst.scss"

export default function CareerFirstComponent({data}) {

  return (
    <>
      <section className="big-banner">
        <div className="container">
          <div className="img-wrapper desktop-show">
            <img src={data?.featuredImage?.node?.localFile?.publicURL} alt={data.title} />
          </div>
          <div className="img-wrapper mobile-show">
            <img src={data?.careerFields?.mobileFeaturedImage?.localFile?.publicURL} alt={data.title} />
          </div>
        </div>
      </section>
    </>
  )
}
